import { dev } from '$app/environment';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { version } from '$app/environment';

const onError = init(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		environment: dev ? 'development' : 'production',
		release: version,
		replaysSessionSampleRate: dev ? 1.0 : 0.1,
		replaysOnErrorSampleRate: 1.0,
	},
	enableInDevMode: true,
});

export const handleError = onError();
